import React from 'react'
import './index.scss'

const Preloader = () => {
	return (
		<div className="Preloader">
			{/* <svg viewBox="0 0 40 34" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect
					width="17.4143"
					height="29.3177"
					rx="6.61301"
					transform="matrix(-0.707107 0.707107 0.707107 0.707107 12.7197 0.406738)"
					fill="#E5663B"
				></rect>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M30.4982 3.78732C29.6739 2.9862 28.3562 3.00502 27.5551 3.82935L10.3326 21.5509C9.53149 22.3752 9.55031 23.6929 10.3746 24.494L10.7007 24.8109C10.8482 24.594 11.0955 24.45 11.3777 24.446C11.8375 24.4394 12.2155 24.8068 12.222 25.2666C12.2261 25.5487 12.0892 25.8001 11.8766 25.9537L12.9079 26.9559C13.0562 26.8043 13.2622 26.709 13.491 26.7058C13.9508 26.6992 14.3288 27.0666 14.3354 27.5263C14.3386 27.7552 14.2492 27.9638 14.1019 28.1164L15.0749 29.0619C15.2234 28.9089 15.4303 28.8126 15.6604 28.8094C16.1201 28.8028 16.4981 29.1702 16.5047 29.6299C16.508 29.86 16.4176 30.0695 16.269 30.2224L17.4188 31.3398C17.5689 31.1561 17.796 31.0375 18.0518 31.0339C18.5115 31.0273 18.8896 31.3947 18.8961 31.8544C18.8998 32.1102 18.7877 32.3406 18.6084 32.4959L19.1809 33.0523C20.0052 33.8534 21.3229 33.8346 22.124 33.0102L39.3465 15.2887C40.1476 14.4644 40.1288 13.1467 39.3045 12.3456L37.1515 10.2533C36.5925 11.1162 35.6267 11.693 34.5215 11.7088C32.7623 11.7339 31.3157 10.3281 31.2906 8.56887C31.2748 7.46363 31.8238 6.48181 32.6704 5.89835L30.4982 3.78732Z"
					fill="#FFB85E"
				></path>
				<path
					d="M26.2844 13.9787C24.6775 16.9373 20.0979 16.3524 17.7103 13.9788L22.0148 9.73414C22.0148 9.73414 22.6762 10.3955 26.2844 13.9787Z"
					fill="black"
					fillOpacity="0.05"
				></path>
				<path
					d="M26.0325 13.7126C24.5038 16.527 20.183 15.9789 17.9116 13.7209L17.7102 13.5207L21.7628 9.46803C21.7628 9.46803 22.4243 10.1294 26.0325 13.7126Z"
					fill="#E5663B"
				></path>
			</svg> */}

			<img src="/assets/images/logo.png" width="213px" height="61px" alt="logo image" />
		</div>
	)
}

export default Preloader
